import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Forms from './modals/forms';
export default function closeClub({name, form, urls, field}) {
  const [openForm, setOpenForm] = useState(false);

  const router = useRouter();
  const { club, code } = router.query;

  useEffect(() => {
    if (urls.includes(club)) setOpenForm(true)
  }, [])
  return (
    <>
      <button onClick={() => {setOpenForm(true)}} className={"mt-2 w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform focus:outline-none rounded bg-gray-700 hover:bg-gray-600 focus:bg-gray-600"}>
        {name}
      </button>
      <Forms isOpen={openForm} setIsOpen={setOpenForm} name={name} form={form} field={field} code={code||""} clubOpen={false}/>
    </>
  )
}