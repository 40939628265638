import { Dialog, Transition, Listbox } from '@headlessui/react'
import { Fragment, useState, useRef } from 'react'
export default function About({isOpen, setIsOpen}) {
  function closeModal() { setIsOpen(false)}

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal} >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true" >
              &#8203;
            </span>
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
              <div className="inline-block w-full max-w-xl p-3 md:p-6 md:my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-100 dark:bg-gray-800 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-lg font-medium text-center leading-6 dark:text-white" >
                  Learn More
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 dark:text-gray-300">
                    The John Jay Attendance Portal is provided by the <a className="hover:text-gray-600 dark:hover:text-gray-100 font-bold focus:outline-none underline hover:no-underline" href="//interest.jaybots.org" target="_BLANK">John Jay Robotics Club</a>.
                    Before it was made available for other clubs, the robotics club used the portal internally.
                    The portal integrates with Google Sheets to provide an easy to access overview of attendance across hundreds of students.<br/><br/>
                    
                    Any club at John Jay Senior High School is eligible to use the portal to manage their attendance, completely free of cost.
                    Interested clubs should email us at <a className="hover:text-gray-600 dark:hover:text-gray-100 font-bold focus:outline-none" href="mailto:anish@jaybots.org">anish@jaybots.org</a> to 
                    schedule a demo and get the portal implemented into their attendance system.<br/><br/>
                    Created by Anish &amp; Robert Z.
                  </p>
                </div>
                <div className="mt-4">
                  <button onClick={closeModal} type="button" className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-600 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-grey-500">
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>   
    </>
  )
}
