import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useRef } from 'react'
import { useRouter } from 'next/router'
export default function Home({isOpen, setIsOpen, name, form, field, code, clubOpen=false}) {
  const [loadCount, setLoadCount] = useState(0)

  function closeModal() { 
    setIsOpen(false)
    setLoadCount(0)
  }
  function loadChange(event) { 
    setLoadCount(loadCount+1)
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={() => {}} >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true" >
              &#8203;
            </span>
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
              <div className="inline-block w-full max-w-xl p-3 md:p-6 md:my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-100 dark:bg-gray-800 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-2xl text-center font-semibold leading-6 dark:text-white" >
                  {name} Attendance
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 dark:text-gray-300">
                    <iframe onLoad={loadChange} src={"https://docs.google.com/forms/d/e/"+form+"/viewform?embedded=true&"+field+"="+code} style={{"width":"100%","min-height": loadCount == 2 ? "350px" : "500px"}} frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                  </p>
                </div>
                <div className="mt-4">
                  {(loadCount !== 2) && <>
                    <a href={"https://docs.google.com/forms/d/e/"+form+"/viewform?"+field+"="+code} target="_BLANK" type="button" className="mb-2 w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-600 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-grey-500">
                      Form not loading?
                    </a>
                  </>}
                  {(loadCount !== 2 && clubOpen) && <>
                    <button onClick={() => {if(!confirm("Are you sure? You haven't submitted attendance yet.")) return;closeModal()}} type="button" className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-600 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-grey-500">
                      Close (Attendance Not Submitted!)
                    </button>
                  </>}
                  {(loadCount == 2 || !clubOpen) && <>
                    <button onClick={closeModal} type="button" className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-600 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-grey-500">
                      Close
                    </button>
                  </>}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>   
    </>
  )
}
