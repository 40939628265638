import Head from 'next/head'
import { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { OpenClub, CloseClub, Source, About } from '../components'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export default function New() {
  const [data, setData] = useState("")
  const [openCount, setOpenCount] = useState(0)
  const [displayedCount, setDisplayedCount] = useState(0)
  const [learnOpen, setLearnOpen] = useState(false)
  
  async function getData() {
    const data = await fetch('/api/clubs')
    const dataJson = await data.json()
    setData(dataJson)
    
    let count = 0;
    let display = 0;
    for (let club of dataJson){
      if (club.open && club.display) count ++
      if (club.display) display++
    }
    setOpenCount(count)
    setDisplayedCount(display)
  }
  useEffect(async () => {
    getData()
  }, [])
  
  return (
    <div className="">
      <Head>
        <title>John Jay Attendance Portal</title>
        <link href="/main.css" rel="stylesheet" type="text/css" />
        <Source />
      </Head>
      <SkeletonTheme baseColor="#808080" highlightColor="#202020">
        <div className="relative dark:bg-gray-900 min-h-screen min-w-screen">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="flex max-w-sm mx-auto bg-white rounded-lg shadow-lg dark:bg-gray-800 lg:max-w-4xl xl:max-w-6xl w-full">
              <div className="hidden bg-contain bg-center lg:block lg:w-1/2" style={{"backgroundImage":"url('https://cdn.discordapp.com/attachments/769275863786455057/913234503617626182/Tempfile.png')"}}></div>
              <div className="w-full px-6 py-8 md:px-8 lg:w-1/2">
                <h2 className="text-2xl font-semibold text-center text-gray-700 dark:text-white">Club Attendance</h2>
                <p className="text-lg text-center text-gray-600 dark:text-gray-200">Select an active club to continue.</p>
                {(data) && <>
                  {data.map((club) => {
                    return (club.open && club.display) ? <OpenClub name={club.name} icon={club.logo} form={club.form} color={club.color} design={"2"} urls={club.urls} field={club.field}/> : ""
                  })}
                  {(openCount == 0) && <>
                    <a className={"flex items-center justify-center mt-4 text-gray-600 transition-colors duration-200 rounded-lg dark:text-gray-200 border-2 border-blue-600 cursor-not-allowed"}>
                      <span className="py-3 font-bold text-left">No Active Clubs</span>
                    </a>
                  </>}
                </>}
                {(!data) && <>
                  <Skeleton height={50} count={1} count={1} className="mt-4"/>
                </>}

                <div className="flex items-center justify-between mt-4">
                  <span className="w-1/5 border-b dark:border-gray-600 lg:w-1/4"></span>
                  <a className="text-xs text-center text-gray-500 uppercase dark:text-gray-400">or view other clubs</a>
                  <span className="w-1/5 border-b dark:border-gray-400 lg:w-1/4"></span>
                </div>

                <div className="mt-2">
                  {(data) && <>
                    {data.map((club) => {
                      return (!club.open && club.display) ? <CloseClub name={club.name} form={club.form} urls={club.urls} field={club.field}/> : ""
                    })}
                    {(openCount == displayedCount) && <>
                      <button className={"mt-2 w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform focus:outline-none rounded bg-gray-700 opacity-80 cursor-not-allowed"}>
                        No Inactive Clubs
                      </button>
                    </>}
                  </>}
                  {(!data) && <>
                    <Skeleton height={40} count={1} count={3} className="mt-2"/>
                  </>}
                </div>
                
                <div className="flex items-center justify-between mt-4">
                  <span className="w-1/4 border-b dark:border-gray-600 md:w-1/3"></span>
                  <a onClick={()=>{setLearnOpen(true)}} className="text-xs text-gray-500 uppercase dark:text-gray-400 hover:underline">Learn More</a>
                  <span className="w-1/4 border-b dark:border-gray-600 md:w-1/3"></span>
                </div>
                <div className="mt-4">
                  <a onClick={()=>{setLearnOpen(true)}} className={"w-full block text-center px-4 py-2 tracking-wide text-white transition-colors duration-200 transform focus:outline-none rounded bg-gray-700 opacity-80 hover:opacity-100 focus:opacity-100"}>
                    Additional Information
                  </a>
                  <a href="//interest.jaybots.org" target="_BLANK" className={"w-full block text-center px-4 py-2 tracking-wide text-white transition-colors duration-200 transform focus:outline-none rounded bg-gray-700 opacity-80 hover:opacity-100 focus:opacity-100 mt-2"}>
                    Made by John Jay Robotics
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
      <About isOpen={learnOpen} setIsOpen={setLearnOpen}/>
    </div>
  )
}